import React from 'react';
import { Redirect } from 'react-router-dom';
import { RouteConfig } from 'react-router-config';

import { SearchPage } from '@pages/search';

import { App } from './_app';

export const routes: RouteConfig[] = [
  {
    component: App,
    routes: [
      { path: '/search', exact: true, component: SearchPage },
      {
        render: () => <Redirect to='/search' />,
      },
    ],
  },
];
