import React from 'react';
import clsx from 'clsx';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
// import { Form } from 'react-final-form';
// import data from './data.json';

const additionalFilters = [
  {
    name: 'Currency',
    shortname: 'currency',
    defaultValue: 'USD',
    options: [
      { name: 'USD', value: 'USD' },
      { name: 'EUR', value: 'EUR' },
      { name: 'RUB', value: 'RUB' },
      { name: 'UAH', value: 'UAH' },
    ],
  },
  {
    name: 'Sort',
    shortname: 'sort',
    defaultValue: 'relevance',
    options: [
      { name: 'Relevance', value: 'relevance' },
      { name: 'Lowest Price', value: 'lowest' },
    ],
  },
  {
    name: 'Availabillity',
    shortname: 'availabillity',
    defaultValue: 'all',
    options: [
      { name: 'All', value: 'all' },
      { name: 'In stock only', value: 'stock-only' },
    ],
  },
];

export function SearchForm() {
  return (
    <StyledForm>
      <Typography variant='body1' className='form-title'>
        Filter Your Search:
      </Typography>
      <StyledMainControls>Main controls</StyledMainControls>
      <StyledAdditionalControls>
        {additionalFilters.map(({ defaultValue, shortname, name, options }) => (
          <FormControl
            key={shortname}
            className={clsx('additional-filter', shortname)}
          >
            <InputLabel htmlFor={shortname}>{name}</InputLabel>
            <Select
              defaultValue={defaultValue}
              inputProps={{
                name: shortname,
                id: shortname,
              }}
            >
              {options.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
      </StyledAdditionalControls>
    </StyledForm>
  );
}

const StyledMainControls = styled.div``;

const StyledAdditionalControls = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  justify-content: space-between;

  .additional-filter {
    .currency {
      width: 60px;
    }

    &:not(.currency) {
      width: 120px;
    }
  }
`;

const StyledForm = styled.div`
  .form-title {
    font-weight: bold;
    margin: 20px 0;
  }
`;
