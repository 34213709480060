import { blue, red } from '@material-ui/core/colors';

import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: red,
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});
