import React from 'react';
import { Container } from '@material-ui/core';
// import { RouteConfigComponentProps } from 'react-router-config';
import { SearchForm } from '@features/search/components/search-form';

// type SearchPageProps = RouteConfigComponentProps & {};

export function SearchPage() {
  return (
    <Container>
      <SearchForm />
    </Container>
  );
}
