import React from 'react';
import { Router } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { ThemeProvider as MuiThemeProvier } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from 'styled-components';
import { renderRoutes } from 'react-router-config';
import { history } from '@lib/routing';

import { theme } from './theme';

import { routes } from './_routes';

function Root() {
  return (
    <NoSsr>
      <MuiThemeProvier theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router history={history}>{renderRoutes(routes)}</Router>
        </ThemeProvider>
      </MuiThemeProvier>
    </NoSsr>
  );
}

export default hot(Root);
